@font-face {
  font-family: figtree-black;
  src: url(fonts/static/Figtree-Black.ttf);
}
@font-face {
  font-family: figtree-light;
  src: url(fonts/static/Figtree-Light.ttf);
}
@font-face {
  font-family: satoshi;
  src: url(fonts/satoshi/Satoshi-Regular.ttf);
}
@font-face {
  font-family: satoshi-light;
  src: url(fonts/satoshi/Satoshi-Light.ttf);
}
@font-face {
  font-family: satoshi-light-italic;
  src: url(fonts/satoshi/Satoshi-LightItalic.ttf);
}
@font-face {
  font-family: satoshi-bold;
  src: url(fonts/satoshi/Satoshi-Bold.ttf);
}
@font-face {
  font-family: satoshi-bold-italic;
  src: url(fonts/satoshi/Satoshi-BoldItalic.ttf);
}
@font-face {
  font-family: satoshi-italic;
  src: url(fonts/satoshi/Satoshi-Italic.ttf);
}

.App {
  text-align: center;
  margin: auto;
  background-color: crimson;
}

body {
  margin: 0;
  overflow-x: hidden;
}

* {
  outline: none;
  box-sizing: border-box;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple ColorE moji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-tap-highlight-color: transparent;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* div {
  font-family: satoshi;
  font-size: calc(var(--card-size) * 0.26);
} */


/* div {
  font-family: satoshi;
  font-size: calc(var(--card-size) * 0.23);
}

span {
  font-size: calc(var(--card-size) * 0.22);
}

button {
  font-size: calc(var(--card-size) * 0.1);
} */

.streak {
  text-align: center;
  font-size: calc(var(--card-size) * .24);
}

:root {
  /* For themes */
  --background: #ebeced;
  --text-primary: #332d2d;
  --text-secondary: #888488;
  --card1-bg-color: rgb(37, 150, 190);
  --card2-bg-color: rgb(62, 62, 13);
  --card3-bg-color: #e86327;
  --trump-card-color: #332d2d;
  --target-bg-color: rgb(241, 241, 241);
  --error-bg-color: red;

  /* Card colors */
  --card-border-color: #d3d6da;
  --trump-color-normal: #332d2d;
  --trump-color-dark-theme: #fbfbfb;
  --square-size: min(
    calc(80vw / (3 + 2)),
    calc((calc((70vh - 60px - 30vh) / 7)))
  );

  /* FONTS 
        dk - large devices
        mb - mobiles
  */
  --font-english: calc(var(--card-size) * 0.26);
  --font-tamil: calc(var(--card-size) * 0.21);
  --font-hindi: calc(var(--card-size) * 0.23);
  --tuto-telugu: calc(var(--card-size) * 0.22);
  --tuto-odiya: calc(var(--card-size) * 0.22);
  --mb-tuto-tamil : calc(var(--card-size) * .19);


  --mb-button-tuto : calc(var(--card-size) * .17);
  --dk-button-tuto : calc(var(--card-size) * .22);
  --mb-quick-tamil : calc(var(--card-size) * 0.15);
  --dk-quick-tamil : 13px;
  --english-tuto : calc(var(--card-size) * 0.26);
  --quick-modal-button : 14px;
  --english-tuto-button : calc(var(--card-size) * 0.21);

  --stats-squares: calc(var(--card-size) * .26);
  --mb-stats-tamil: calc(var(--card-size) * .18);
  --dk-stats-tamil : calc(var(--card-size) * .27);

  --intro-korean: calc(var(--card-size) * .22);

  --container-button : calc(var(--card-size) * .36);
  --dk-container-english: calc(var(--card-size) * .33);
  --mb-container-tamil : calc(var(--card-size) * .24);
  --dk-container-tamil : calc(var(--card-size) * .28);

  --alert-english : calc(var(--card-size) * .24);
  --mb-alert-tamil : calc(var(--card-size) * .18);
  --dk-alert-tamil : calc(var(--card-size) * .21);
 
  /* Grid styles */
  --player-count: 3;
  --card-count: 4;
  --card-size: calc(min(calc((100vw - var(--card-gap) * 10) / (2 + 3)), calc((calc((100vh - 60px - 30vh) / 8)))) - 10px);
  --dummy-card-size: calc(min(calc((100vw - var(--card-gap) * 10) / (3 + 2)), calc((calc((100vh - 60px - 30vh) / 8)))) - 10px);

  --dummy-card-size: calc(min(calc((100vw - var(--card-gap) * 10) / (3 + 2)), calc((calc((100vh - 60px - 30vh) / 8)))) - 10px);

  /* for first guide container */
  --guide-card: calc(min(calc((100vw - var(--card-gap) * 10) / (3 + 1)), calc((calc((100vh - 60px - 30vh) / 9)))) - 13px);

  --card-size-guide: calc(min(calc((100vw - var(--card-gap) * 10) / (3 + 2)), calc((calc((100vh - 60px - 30vh - 5vh) / 7)))) - 1px);
  --card-gap: 6px;

  /* ////////////////////////////////////////// colors colors colors colors colors/////////////////////////////////////////// */
  /* pallete colors */
  --dull-white: #b4b4b4;
  --button-shadow: #5b5e60;
  --dull-black: #414141;
  --practice-black: #585757;
  --white: white;
  --black: #332d2d;
  --red: #dc4c64;
  --green: #14a44d;
  --yellow: #e4a11b;
  --card-yellow: #f5f52f;
  --dark-blue: #3b71ca;
  --blue: #54b4d3;
  --hand-highlight: rgb(45 45 45 / 0.47);
  --placeholder-black: rgba(61, 61, 61, 0.2);
  --placeholder-white: rgba(255, 255, 255, 0.3);
  --light-navbar-line: rgb(233 233 233);
  --lm-highlight: rgb(233, 233, 233);

  /* body colors */
  --dark-body: rgb(51, 51, 51);
  --light-body: #fbfbfb;

  /* modal/container background colors (eg: walkthrough background) */
  --dark-container: rgb(37, 37, 38);
  --light-container: rgb(234, 229, 190);

  --light-active-column: rgba(45, 45, 45, 0.47);
  --font-color-tuto: rgba(45, 45, 45, 0.7);
  --dark-active-column: var(--white);

  /* target ball color */
  --ball-color: var(--blue);
}

[data-theme="dark"] {
  --background: #332d2d;
  --text-primary: rgb(174, 167, 167);
  --text-secondary: rgb(169, 165, 165);
}

.font-class {
  font-size: small;
  /* font-size: calc(var(--card-size) * .27); */
}

.tamil-font {
  font-size: var(--font-tamil);
}

nav {
  position: relative;
  top: 0;
  width: 100%;
  display: grid;
  grid-column-end: auto;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #332d2d;
  box-shadow: 0px 2px 0px #e9e9e9;
}

p {
  font-family: satoshi-bold;
}

div {
  font-family: satoshi-light;
}

a {
  font-family: satoshi-light;
}


.title {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2 / 5;
  font-size: 1.3rem;
  font-weight: bold;
}

.ul-right {
  list-style-type: none;
  justify-self: end;
  margin: auto;
  margin-right: 0.5vw;
  display: flex;
}

.ul-left {
  list-style-type: none;
  justify-self: start;
  display: flex;
  margin: 0;
  padding-left: 0px;
  margin-left: 0.5vw;
}

.tutorial-top {
  transform: translate(0, -58%);
}

.init-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  cursor: pointer;
  margin-left: 1.5vw;
  gap: 0.5vw;
}

.icon1 {
  cursor: pointer;
  margin: 2px 2px 2px 2px;
}

.icon2 {
  cursor: pointer;
  margin: 2px 2px 2px 2px;
}

.guide-card {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0px;
  font-size: calc(var(--card-size) * 0.36);
  margin: 0;
  border-radius: 4px;
  border: 1px solid #3a3a3c;
  width: var(--card-size-guide);
  height: var(--card-size-guide);
}
/* 
.flex-box-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */

/* .squareCards {
  width: 10px;
  height: 10px;
  background-color: #332d2d;
  border-radius: 3px;
  margin: 2px 2px 2px 2px;
} */

/* To remove */
.flex-share {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  background-color: #069c6c;
  font-size: 2rem;
}


.button-share {
  border: none;
  background-color: #332d2d;
  width: fit-content;
  margin: auto;
  font-weight: 400;
}
/* To remove */

/* .share-text {
  display: flex;
  justify-content: flex-start;
  padding: 2vh 5vh 2vh 4vh;
} */

.whole-container-flex {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  /* margin-top: 15vh; */
  gap: calc(var(--card-gap) * 2);
}

.trick-item-grid {
  display: grid;
  grid-template-columns: repeat(calc( var(--player-count)), auto);
  grid-template-rows: repeat(1, auto);
  row-gap: 10px;
  column-gap: var(--card-gap);
}

/* .trick-added {
  display: grid;
  grid-template-columns: repeat(3, auto);
  width: fit-content;
  margin: auto;
  grid-row: 1 / 2;
} */

.trick-card {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0px;
  font-family: satoshi-bold;
  font-size: calc(var(--card-size) * 0.36);
  margin: auto;
  border-radius: 4px;
  background-color: white;
  width: var(--card-size);
  height: var(--card-size);
}
.modal-card {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0px;
  font-size: calc(var(--card-size) * 0.36);
  margin: auto;
  border-radius: 4px;
  background-color: white;
  border: 1px solid var(--placeholder-black);
  width: var(--guide-card);
  height: var(--guide-card);
}

.trick-card1 {
  /* display: flex; */
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: none;
  padding: 6px;
  font-size: calc(var(--card-size) * 0.36);
  border-radius: 0.5rem;
  /* box-shadow: inset 0 0 0 var(--card-gap) var(--light-container); */
}

.mid-div {
  background-color: var(--hand-highlight);
}

.button-trick {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 6px;
  margin-top: 18px;
  font-size: calc(var(--card-size) * 0.36);
  border-radius: 4px;
}

.win-trick-card {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0px;
  font-size: calc(var(--card-size) * 0.36);
  margin: 0 var(--card-gap);
  border-radius: 4px;
  background-color: white;
  border: 1px solid #3d3d3d;
  width: var(--card-size);
  height: var(--card-size);
}

/* .trick-card::after {
  /* background-color: #ffffff;  
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  display: block;
  animation: trick-shrink 3s 1s 3 normal forwards;
} */

.font-no {
  /* font-size: calc(var(--dummy-card-size) * 0.36); */
  animation: un-shrink 0.75s 0.25s;
  animation-fill-mode: forwards;
}
.rule1 {
  margin-top: calc(var(--card-size) / 4);
}
.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.text-align-center {
  text-align: center;
}
.tab {
  width: calc(var(--card-size) * 2);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ball-color);
  font-family: satoshi;
  text-align: center;
  color: var(--white);
}
.rules {
  border-top: 2px solid var(--ball-color);
  border-left: 2px solid var(--light-navbar-line);
  border-right: 2px solid var(--light-navbar-line);
  border-bottom: 2px solid var(--light-navbar-line);
  border-radius: 0 0 0.5rem 0.5rem;
  padding: calc(var(--card-size) / 4);
  height: calc(var(--card-size) * 7.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rules-target-balls {
  width: calc(var(--guide-card) * 2);
  margin-bottom: calc(var(--card-gap) * 3);
}

/* .font-no::after {
  /* content: '';
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0px;
  font-size: calc(var(--dummy-card-size) * 0.36);
  margin: 0 var(--card-gap);
  border-radius: 4px;
  border: 1px solid #3a3a3c;
  width: var(--card-size);
  height: var(--card-size);
  animation: trick-shrink 4s;
  animation-delay: 1s;
  animation-fill-mode: forwards; 
} */

/* For target container grid */
.target-container-grid {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  height: auto;
  margin-bottom: 15px;
  row-gap: 10px;
}

.target-inner-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  border-radius: 5px;
  padding: 1.6px;
  border: 0;
  gap: 1.6px;
  color: rgb(45, 45, 45, 70%);
  background-color: #332d2d;
}

.target-inner-grid > div:last-child {
  border-radius: 0 4px 4px 0;
}

.target-inner-grid > div:first-child {
  border-radius: 4px 0 0 4px;
}

.inner-target-child {
  width: 100%;
  height: 100%;

  text-align: center;
  color: #332d2d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.score-inner-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  background-color: #332d2d;
  border-radius: 5px;
  padding: 1.6px;
  border: 0;
  /* height: 1.5em;
  font-size: 1em; */
  gap: 1.6px;
  color: #069c6c;
}

.outer-border {
  padding: 6px;
  background-color: #069c6c;
  border-radius: 4px;
}

.button-outer-border {
  padding: 6px;
  /* background-color: #069c6c; */
  border-radius: 4px;
}

.score-inner-grid > div:last-child {
  border-radius: 0 4px 4px 0;
}

.score-inner-grid > div:first-child {
  border-radius: 4px 0 0 4px;
}

.score-inner-grid {
  width: 100%;
  height: 100%;
  background-color: #14a44d;
  text-align: center;
}

/* In handgrid */
.hand-item-grid {
  display: grid;
  grid-template-columns: repeat(var(--player-count), auto);
  grid-template-rows: repeat(1, auto);
  column-gap: calc(var(--card-gap) * 2);
}

.full-3-trixk {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 12px;
  background-color: #e3e3e3;
  padding: 6px;
  margin-top: 12px;
  border-radius: .5rem;
}

.empty-3-trixk {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 12px;
  padding: 6px;
  margin-top: 12px;
}

.yesterday-hand-item-grid {
  display: grid;
  grid-template-columns: repeat(
    3,
    calc(var(--card-size) + 2 * var(--card-gap))
  );
  column-gap: calc(var(--card-gap) * 2);
}

.yesterday-hand-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.solution-target {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: calc(var(--card-gap) * 3); */
}

.solution-target-hand {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--card-gap) * 3);
  cursor: default;
  pointer-events: none;
}

.solution-button {
  border-width: 2px;
  border-style: solid;
  border-color: var(--light-active-column);
  border-image: initial;
  color: var(--light-active-column);
  background-color: var(--light-container);
  padding: 12px;
  margin-top: 15px;
  box-shadow: 1px 2px 4px;
  width: calc(var(--card-size) * 4);
}

.trick-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(1, auto);
  /* column-gap: var(--card-gap); */
  width: min-content;
  margin: auto;
  grid-row: 1 / 2;
}

.grid-hand {
  display: flex;
}

.modal-trick {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 6px;
  background: white;
  width: fit-content;
  margin: auto;
  border-radius: 0.5rem;
  margin-bottom: calc(var(--card-size) / 4);
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 6px;
  padding: 6px;
  gap: 6px;
  height: fit-content;
}

.hand-grid-columns {
  padding: var(--card-gap);
  display: grid;
  grid-row: 1 / 2;
  grid-gap: var(--card-gap);
}

.trick-grid-columns {
  /* padding-bottom: var(--card-gap); */
  display: grid;
  grid-row: 1 / 2;
  grid-gap: calc(var(--card-gap) * 2);
  grid-template-columns: repeat(var(--player-count), auto);
  width: fit-content;
  margin: auto;
}

.grid-col {
  display: grid;
  padding-top: 5px;
  /* padding: var(--card-gap) 0px; */
  grid-gap: var(--card-gap);
}

.grid-col-solution {
  display: grid;
  padding-top: 5px;
  padding: var(--card-gap);
  padding-top: 0;
  grid-gap: var(--card-gap);
  border-radius: 0 0 0.5rem 0.5rem;
}

.lives-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lives {
  display: flex;
  font-size: calc(var(--card-size) * 0.26);
  opacity: 0.4;
}

.lives1 {
  display: flex;
  font-size: calc(var(--card-size) * 0.26);
  opacity: 1;
}

.lives2 {
  display: flex;
  font-size: calc(var(--card-size) * 0.26);
  opacity: 0.25;
}

.failure-container {
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  height: 10vh;
  justify-content: center;
  gap: 0.5em;
  /* margin-top: var(--card-gap); */
  width: calc(calc(var(--card-size) * var(--player-count) ) + (var(--card-gap) * ( var(--player-count) + 1 ) * 2.5 ));
  height: var(--card-size);
}

.failure-refresh {
  float: left;
  padding: auto;
  display: flex;
}

.failure-msg {
  margin-left: 0.5em;
  float: right;
}

/* This is for react-transition group */

.stats-squares {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 0px;
  font-size: 12px;
  margin: 0;
  border-radius: 4px;
  border: 1px solid #3a3a3c;
  width: calc(var(--card-size) * 1.5);
  height: calc(var(--card-size) * 1.5);
}

.nxt-cardle {
  font-size: medium;
  text-align: center;
}

.timer {
  /* font-size: large; */
  font-weight: bold;
}

.stats-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--card-size) / 1.5);
  width: calc(var(--card-size) * 3);
  background-color: var(--light-container);
  color: var(--dark-container);
  border-radius: var(--card-gap);
  font-size: initial;
  gap: var(--card-gap);
  cursor: pointer;
  box-shadow: 1px 2px 4px;
}

.two-stats-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: calc(var(--card-size) * 0.5);
}

.stats-footer {
  display: flex;
  flex-direction: column;
  gap: calc(var(--card-gap) * 3);
  align-items: center;
  justify-content: center;
}

.font {
  font-size: calc(var(--card-size) * 0.3);
  font-weight: 600;
  font-family: satoshi;
}

.stats-container {
  display: grid;
  padding: 20px;
  /* padding-bottom: 0; */
  grid-template-columns: calc(var(--card-size)) 3fr;
  grid-template-rows: auto;
  column-gap: 44px;
  justify-items: center;
}

.attempts-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--card-gap);
  flex-direction: column;
}

.attempts {
  display: flex;
  align-items: end;
  /* width: calc(var(); */
}

.inner-stats-grid-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(4, auto);
  gap: 16px;
  /* margin-bottom: calc(16px + 7px); */
}

.inner-stats-guess-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  gap: 1.5vw;
}

.inner-stats-guess-items {
  display: flex;
  width: 10px;
  height: 100%;
  align-items: flex-end;
  padding: 0 15px;
}

.retry {
  cursor: pointer;
}

.disable {
  pointer-events: none;
  opacity: 0.3;
}

.active {
  cursor: pointer;
}

/* retry button hover */
/* .retry:hover {
  transform: scale(1.1);
} */

/*Vendum*/
.counter {
  width: 100px;
  font: 800 40px system-ui;
  overflow: hidden;
}

.target-grid {
  width: calc(calc(var(--card-size) * 3) + calc(var(--card-gap) * 14));
  height: calc(var(--card-size) * 2);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: var(--card-gap);
  margin-bottom: var(--card-gap);
}

.trick-balls-container {
  width: calc(var(--card-size) * 3.4);
  height: calc(var(--card-size) / 3.4);
  margin: 0 auto;
  margin-top: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--card-gap);
  /* margin-bottom: var(--card-gap); */
}

.trick-balls {
  width: calc(var(--card-size) / 3.4);
  height: calc(var(--card-size) / 3.4);
  background-color: var(--ball-color);
  border-radius: 50%;
}
.target-cards-container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(var(--player-count), calc(var(--card-size) + var(--card-gap)));
  column-gap: calc(var(--card-gap) * 3);
  justify-content: center;
  text-align: center;
}
.rules-target-trick-container {
  display: grid;
  grid-template-columns: repeat(3, calc(var(--guide-card) + var(--card-gap)));
  column-gap: calc(var(--card-gap) * 2);
  justify-content: center;
  text-align: center;
}

.target-item {
  display: grid;
  row-gap: calc(var(--card-gap) * 0.8);
  align-items: center;
  justify-content: center;
}
.rules-target-trick-item {
  display: grid;
  row-gap: calc(var(--card-gap) * 2);
  align-items: center;
  justify-content: center;
  /* border: 1px solid white; */
}
.rules-target {
  height: var(--guide-card);
  width: var(--guide-card);
  border: 1px solid green;
}
.broken-ball {
  height: calc(var(--card-size) / 4);
}

.target-cards {
  width: calc(var(--card-size) + (2 * var(--card-gap)));
  height: calc(var(--card-size) + (2 * var(--card-gap)));
  display: grid;
  grid-template-columns: repeat(2, calc(var(--card-size) / 2));
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: var(--card-gap) solid var(--light-container);
  border-radius: 0.5rem;
}

.target-balls-placeholder {
  width: calc(var(--card-size) / 2.51);
  height: calc(var(--card-size) / 2.51);
  border: 1px solid var(--ball-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.target-balls-guide {
  width: calc(var(--card-size) / 4);
  height: calc(var(--card-size) / 4);
  border: 1px solid var(--ball-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-inner {
  display: grid;
  width: calc(var(--card-size) / 2);
  height: calc(var(--card-size) / 2);
}

/* .target-balls {
  width: 80%;
  height: 80%;
  background-color: var(--ball-color);
  border-radius: 50%;
} */

.card-inner {
  display: flex;
  width: calc(var(--card-size) * 0.5);
  height: calc(var(--card-size) * 0.5);
  justify-content: end;
  align-items: flex-end;
}

.class-inner-1 {
  display: flex;
  justify-content: end;
}
.class-inner-2 {
  display: flex;
  justify-content: start;
  align-items: self-end;
}
.class-inner-3 {
  display: flex;
  justify-content: end;
  align-items: baseline;
}
.class-inner-4 {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

#ball-placeholder-1 {
  margin: calc(var(--card-size) / 12.36) calc(var(--card-size) / 38.8)
    calc(var(--card-size) / 54.4) calc(var(--card-size) / 13.6);
}
#ball-placeholder-2 {
  margin: calc(var(--card-size) / 12.36) calc(var(--card-size) / 12.36)
    calc(var(--card-size) / 54.4) calc(var(--card-size) / 38.8);
}
#ball-placeholder-3 {
  margin: calc(var(--card-size) / 54.4) calc(var(--card-size) / 38.8)
    calc(var(--card-size) / 12.36) calc(var(--card-size) / 13.6);
}
#ball-placeholder-4 {
  margin: calc(var(--card-size) / 54.4) calc(var(--card-size) / 12.36)
    calc(var(--card-size) / 12.36) calc(var(--card-size) / 38.8);
}

/* Its for box-shadow: method */
/* #ball-placeholder-1 {
  margin: calc(var(--card-size) / 10.36) calc(var(--card-size) / 38.8)
    calc(var(--card-size) / 54.4) calc(var(--card-size) / 6.6);
}
#ball-placeholder-2 {
  margin: calc(var(--card-size) / 12.36) calc(var(--card-size) / 12.36)
    calc(var(--card-size) / 54.4) calc(var(--card-size) / 11.8);
}
#ball-placeholder-3 {
  margin: calc(var(--card-size) / 54.4) calc(var(--card-size) / 38.8)
    calc(var(--card-size) / 12.36) calc(var(--card-size) / 6.6);
}
#ball-placeholder-4 {
  margin: calc(var(--card-size) / 54.4) calc(var(--card-size) / 12.36)
    calc(var(--card-size) / 12.36) calc(var(--card-size) / 11.8);
} */

/* For guide tutorial*/
#guide-ball-placeholder-1 {
  margin: calc(var(--guide-card) / 12.36) calc(var(--guide-card) / 38.8)
    calc(var(--guide-card) / 54.4) calc(var(--guide-card) / 13.6);
}
#guide-ball-placeholder-2 {
  margin: calc(var(--guide-card) / 12.36) calc(var(--guide-card) / 12.36)
    calc(var(--guide-card) / 54.4) calc(var(--guide-card) / 38.8);
}
#guide-ball-placeholder-3 {
  margin: calc(var(--guide-card) / 54.4) calc(var(--guide-card) / 38.8)
    calc(var(--guide-card) / 12.36) calc(var(--guide-card) / 13.6);
}
#guide-ball-placeholder-4 {
  margin: calc(var(--guide-card) / 54.4) calc(var(--guide-card) / 12.36)
    calc(var(--guide-card) / 12.36) calc(var(--guide-card) / 38.8);
}
.trick-card-container {
  background-color: red;
  width: calc(var(--card-size) + calc(var(--card-gap) * 2));
  height: calc(var(--card-size) + calc(var(--card-gap) * 2));
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
}
.empty-card {
  height: 1.2rem;
  width: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.15rem
}

.empty-trump-outer {
  width: calc(var(--card-size) * 0.4);
  height: calc(var(--card-size) * 0.4);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.15rem;
}
.empty-trump-inner {
  width: calc(var(--card-size) * 0.3);
  height: calc(var(--card-size) * 0.3);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  font-size: 0.75;
  border-color: #fbfbfb;
  /* padding-bottom: 1px; */
}
.no-breaking {
  white-space: nowrap;
  line-height: 1.7;
}

.hand-animation {
  animation: winning-hand 2s;
}

.trick-animate {
  animation: trick-shrink 0.75s 0.25s;
  animation-fill-mode: forwards;
  border-radius: 7px;
}

.intro-target,
.final-target {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 0.5rem;
}
.three-buttons-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  text-align: center;
  /* margin-bottom:calc(var(--card-gap) * 3); */
}
.three-buttons {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.repeat-btn {
  margin-left: 0px;
}

.next-btn {
  margin-left: 0px;
}

.light-font {
  font-family: satoshi-light;
  text-align: center;
}

.shrinkCard {
  scale: 0.6;
}

.scaleUpNumber {
  scale: 1.4;
}

.rules-trick {
  padding-right: 0;
}

.infiniteBallFadeIn {
  animation: InfiniteFadeIn 2000ms linear infinite;
}

.infiniteBallFadeOut {
  animation: InfiniteFadeOut 2000ms linear infinite;
}

.ballFadeIn {
  animation: fadeIn 1000ms 1;
  animation-timing-function: linear;
}
.ballFadeOut {
  animation: fadeOut 1000ms 1;
  animation-timing-function: linear;
}
.tutorial-grid {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--card-gap) * 2.5);
  /* width: fit-content; */
}

.hint-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--card-gap);
}

.tutorial-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  font-weight: 600;
}

.click-size {
  font-size: medium;
  font-weight: bolder;
}

.button-tut {
  display: grid;
  justify-content: end;
  align-items: flex-end;
}

.hand-animation {
  animation: winning-hand 2s;
}
.intro-target,
.final-target {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 0.5rem;
}
.three-buttons-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  /* text-align: center; */
  /* margin-bottom:calc(var(--card-gap) * 2); */
}
.three-buttons {
  margin: 0 auto;
}
.repeat-btn {
  margin-left: 0px;
}

/* Animations */

.guide-container {
  margin: auto;
  /* margin-top: 3vh; */
  width: calc(var(--card-size) * (2 + 5) + var(--card-gap) * (3 * 5 - 3));
  font-size: medium;
}

.guide-null {
  margin-top: 20vh;
}

/* New styles for PRACTICE CARDLES */
.title-practicia {
  margin: auto;
  text-align: center;
  padding: 6vh;
  font-size: calc(var(--card-size) * 0.4);
}

.container-buttons {
  width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.arrow-buttons {
  display: flex;
  width: calc( ( 100% / 3 ) - 12.5px );
  justify-content: space-evenly;
}

.practice-app {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, calc(var(--card-size) * 4.5));
  gap: calc(var(--card-gap) * 2);
  height: 60vh;
  grid-row: 1 / 2;
}

.practice-app-18 {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, auto));
  gap: 20px;
  width: 60vw;
  height: 60vh;
}

.banner {
  color: #ffffff;
  text-align: center;
  top: 25px;
  position: absolute;
  transform: rotate(45deg);
  background-color: var(--red);
  right: calc(var(--card-size) * -0.7);
  padding: 1px 35px;
  border-bottom: transparent;
}

.view {
  overflow: hidden;
  position: relative;
  width: 250px;
}

img {
  width: 100%;
}

.container {
  /* width: 250px; */
  height: 250px;
  position: relative;
  background-color: transparent;
}

.corner {
  position: fixed;
  width: 0;
  height: 0;
  right: 0;
  /* border-top: 150px solid #ffcc00; */
  border-bottom: 100px solid transparent;
  border-right: 100px solid var(--yellow);
  border-left: 100px solid transparent;
}

.corner span {
  position: absolute;
  top: calc(var(--card-gap) * 4.4);
  width: 100px;
  left: calc(var(--card-gap) * 2.7);
  text-align: center;
  font-size: 16px;
  font-family: arial;
  transform: rotate(45deg);
  display: block;
}

.cards-in {
  background-color: #0fa650;
}

.container-practice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: calc(var(--card-gap) * 2);
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%,-58%);
}

.practice-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: calc(var(--card-gap) * 1);
  position: absolute;
  bottom: 1vh;
  left: 50%;
  transform: translate(-50%);
}

.cards-grid {
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  font-size: calc(var(--card-size) * 0.36);
  font-weight: 500;
  font-family: "poppins";
  margin: 0;
  border-radius: 10px;
  position: relative;
}

.selector-button{
  margin-top: 0;
}

.line1 {
  margin-bottom: 10px;
  width: 90%;
  border-width: 0;
  border: 0;
  border-bottom: 1px solid #d3d6da;
}

.flexx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: small;
  white-space: nowrap;
  width: 88%;
}

.level {
  opacity: 0.7;
  font-weight: 800;
  /* font-size: large; */
}

.alert-size {
  width: max-content;
}

.selected-lang {
  font-family: "satoshi-light";
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.lang-list{
      box-shadow: 0px 0px 5px 0px;
    width: calc(var(--guide-card) * 6);
    height: calc(var(--guide-card) * 4);
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 100%;
}
.lang-item{
      display: grid;
    grid-template-columns: repeat(2,50%);
    padding: calc(var(--guide-card) /5);
    cursor: pointer;
    padding-left: 14px;
}
.lang-item:hover{
  background-color: var(--green);
  
}
.script{
  color: white;
}
.eng-script {
  font-family: "satoshi-light";
}
.dull-lang{
  
    display: grid;
    grid-template-columns: repeat(2,50%);
    padding: calc(var(--guide-card) /5);
    padding-left: 14px;
    opacity: 0.3;
    pointer-events: none;
}
.own-script {
  font-family: "satoshi";
}
.light-main-alert{
  padding: 0.5rem;
  color: white;
  text-align: center;
  font-family: "satoshi-light";
}
.dark-main-alert{
  padding: 0.5rem;
  color: var(--black);
  text-align: center;
  font-family: "satoshi-light";
}
@media only screen and (max-width: 550px) {
  .alert-size {
    width: calc(var(--card-size) * 6);
  }
}

.color-dull-white {
  color: var(--dull-white);
}

.color-dull-black {
  color: var(--dull-black);
}

.svg-button {
  font-size: 30px;
}

.problem-selector {
  
  display: grid;
  grid-template-columns: repeat(2, calc(var(--card-size) * 6.75));
  border-bottom: 2px solid var(--ball-color);
  gap: calc(var(--card-gap) * 4);
}



@media only screen and (max-width: 900px) {
  .practice-app {
    display: grid;
    grid-template-columns: repeat(3, calc(var(--card-size) * 2));
    gap: calc(var(--card-gap) * 2);
    height: 50vh;
    white-space: unset;
  }

  .selector-button {
    margin-top: 0;
  }

  .problem-selector {
    display: grid;
  grid-template-columns: repeat(2, calc(var(--card-size) * 3));
  border-bottom: 2px solid var(--ball-color);
  gap: calc(var(--card-gap) * 4);
    
  }

  

  .arrow-buttons {
    width: calc((100% / 3) - 10px);
  }

  .cards-grid {
    font-size: calc(var(--card-size) * 0.25);
    position: relative;
  }
  .flexx {
    white-space: unset;
    font-size: calc(var(--card-gap) * 1.5);
  }
}

/* media queries */
@media only screen and (max-width: 440px) {
  .guide-container {
    margin: auto;
    /* margin-top: 3vh; */
    margin-bottom: 4vh;
    font-size: calc(var(--card-size) * 0.25);
    width: calc(100% - 2rem);
  }
}

@media only screen and (max-width: 550px) {
  .target-item {
    display: grid;
    row-gap: calc(var(--card-gap) * 0.3);
    align-items: center;
    justify-content: center;
  }
  .icon2 {
    cursor: pointer;
    /* margin: 0; */
  }
}


@media only screen and (min-width: 1300px) and (max-width: 1440px) {
  .target-item {
    display: grid;
    /* row-gap: calc(var(--card-gap) * 1); */
    align-items: center;
    justify-content: center;
  }
  .alert-size {
    width: "auto";
  }
}

@media only screen and (max-width: 550px) {
  .two-stats-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(var(--card-size) * 0.2);
  }

  .svg-button {
    font-size: 25px;
  }
}

/* @media only screen and (orientation:portrait) {
  body {
    height: 100vw;
    /* transform: rotate(90deg);  
  }
} */

@media only screen and (min-height: 250px) and (max-height: 550px) and (orientation: landscape) {
  #turn {
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  #root {
    display: none;
  }
}

@media only screen and (min-height: 716px) and (max-height: 1024px) and (orientation: landscape) {
  #turn {
    display: none;
  }
  #root {
    display: block;
  }
}

/* @media only screen and (min-width: 1100px) and (min-height: 650px) and (orientation: landscape) {
  #turn {
    display: none;
  }
  #root {
    display: block;
  }
} */

@media only screen and (min-width: 750px) and (max-width: 950px) and (orientation: portrait) {
  #turn {
    display: none;
  }
  #root {
    display: block;
  }
}

@media only screen and (min-width: 320px) and (max-width: 550px) and (orientation: portrait) {
  #turn {
    display: none;
  }
  #root {
    display: block;
  }
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  #root {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

/* This is used before in rolling target animation, this is not useful now.... */
body {
  font-size: 100%;
}

/* These are used before in Help animation, 
  But now these are useless.... :(*/
.help-grid {
  display: grid;
  gap: 20px;
  width: auto;
  grid-template-rows: auto auto auto;
  margin: auto;
  overflow: hidden;
  /* justify-content: space-between; */
}

.target-help-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-row: 1 / 2;
  gap: 7px;
  margin-bottom: -3.3vh;
}

.trick-help-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 7px;
}

.hand-help-grid {
  display: grid;
  gap: 7px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.hand-help1 {
  grid-column: 1/2;
}

.practice-tick{
  display: flex;
  justify-content: end;
  width: 100%;
  position: absolute;
  top: 10%;
  right: 10%;
}

/* .squares {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0px;
  font-size: 20px;
  margin: 0;
  border-radius: 4px;
  border: 1px solid #3a3a3c;
  width: min(calc(80vw / (3+2)), calc((calc((70vh - 60px - 30vh) / 7))));
  height: min(calc(80vw / (3+2)), calc((calc((70vh - 60px - 30vh) / 7))));
} */
/* Keyframes for animations */


@keyframes countNumber {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: -1000px;
  }
}

@keyframes cellBlink {
  0% {
    transform: scale(1);
  }

  50% {
    border-color: #069c6c;
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes winning-target {
  0% {
    border-color: var(--light-container);
  }

  30% {
    border-color: var(--light-container);
  }

  50% {
    border-color: var(--black);
  }

  60% {
    border-color: var(--black);
  }

  100% {
    border-color: var(--light-container);
  }
}

@keyframes dark-winning-target {
  0% {
    border-color: var(--white);
  }

  30% {
    border-color: var(--white);
  }

  50% {
    border-color: var(--black);
  }

  60% {
    border-color: var(--black);
  }

  100% {
    border-color: var(--white);
  }
}

@keyframes winning-hand {
  0% {
    background-color: var(--light-active-column);
  }

  30% {
    background-color: var(--light-active-column);
  }

  50% {
    border-color: var(--black);
  }

  60% {
    background-color: var(--black);
  }

  100% {
    background-color: var(--light-active-column);
  }
}

@keyframes trick-shrink {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.6);
  }
}

@keyframes un-shrink {
  0% {
  }

  100% {
    transform: scale(1.6);
  }
}

@keyframes slider {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  10% {
    opacity: 1;
    transform: translateY(0px);
  }

  25% {
    opacity: 0.7;
    transform: translateY(0px);
  }

  50% {
    opacity: 0.5;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(30px);
  }

  /* 60% {
      opacity: 0; 
      transform: translateY(30px);
    }
    100% {
      opacity: 0; 
      transform: translateY(.7vh);
    } */
}

/* .target-down {
  position: relative;
  animation: targetSlider;
  animation-direction: normal;
  animation-duration: 6s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
} */

@keyframes targetSlider {
  from {
    top: 0px;
  }

  to {
    top: 10vh;
  }

  0% {
    transform: translateY(0px);
    transform: scale(1.02);
  }

  25% {
    transform: translateY(30px);
    transform: scale(1.06);
  }

  50% {
    transform: translateY(40px);
    transform: scale(1.12);
  }

  100% {
    transform: translateY(2vh);
    transform: scale(1.5);
    color: #069c6c;
  }
}

@keyframes cellGrowShort {
  0% {
    transform: scale(1.02);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1.02);
  }
}

@keyframes cellFadeOut {
  0% {
    transform: scale(1.02);
    opacity: 0.9;
  }

  50% {
    transform: scale(0.9);
    opacity: 0.7;
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

@keyframes gridFadeOut {
  0% {
    transform: scale(1.02);
    opacity: 0.9;
  }

  50% {
    transform: scale(0.9);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    /* height: 0; */
    opacity: 0;
  }
}

@keyframes InfiniteFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes InfiniteFadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInGrid {
  0% {
    opacity: 0.5;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes cellGrow {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(1.2);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.02);
  }
}

@keyframes cellWin {
  0% {
    transform: scale(1);
  }

  50% {
    border-color: #0fa650;
    background-color: #0fa650;
    transform: scale(1.05);
  }

  100% {
    border-color: #0fa650;
    color: rgb(0, 0, 0);
    background-color: #0fa650;
    transform: scale(1);
  }
}

@keyframes cellWinTrump {
  0% {
    transform: scale(1);
  }

  30% {
    background-color: #332d2d;
    transform: scale(1.05);
  }

  50% {
    background-color: #332d2d;
    transform: scale(1.2);
  }

  70% {
    background-color: #332d2d;
    transform: scale(1.15);
  }

  100% {
    color: #fbfbfb;
    background-color: #332d2d;
    transform: scale(1);
  }
}

@keyframes winCol {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  25% {
    transform: scale(1.02);
    opacity: 0.6;
  }

  50% {
    transform: scale(1.04);
    opacity: 0.6;
  }

  75% {
    transform: scale(1.02);
    opacity: 0.9;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes counter {
  0% {
    counter-increment: count 1;
  }

  10% {
    counter-increment: count 1;
  }

  20% {
    counter-increment: count 1;
  }

  30% {
    counter-increment: count 1;
  }

  50% {
    counter-increment: count 1;
  }

  75% {
    counter-increment: count 1;
  }

  100% {
    counter-increment: count 0;
  }
}

@keyframes counter1 {
  0% {
    counter-increment: count 2;
  }

  25% {
    counter-increment: count 2;
  }

  50% {
    counter-increment: count 2;
  }

  75% {
    counter-increment: count 1;
  }

  100% {
    counter-increment: count 0;
  }
}

@keyframes opacityReduce {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 0.9;
  }

  10% {
    opacity: 0.8;
  }

  30% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.5;
  }

  70% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes fadeOutra {
  20% {
    opacity: 0.7;
  }

  40% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.3;
  }

  80% {
    opacity: 0.1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes zeroGlow {
  0% {
    opacity: 0.5;
    color: #0fa650;
  }

  5% {
    opacity: 0.7;
    color: #0fa650;
  }

  10% {
    opacity: 0.9;
    color: #0fa650;
  }

  100% {
    opacity: 1;
    color: #0fa650;
  }
}

@keyframes showTarget {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
    transform: scale(1.3);
  }

  100% {
    opacity: 1;
    transform: scale(1.6);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes InfiniteFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes InfiniteFadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
